import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyDDyBPZpUSUQiJ-O-pkPqX4mboFuCJshBk",
    authDomain: "opene-9d3b3.firebaseapp.com",
    projectId: "opene-9d3b3",
    storageBucket: "opene-9d3b3.appspot.com",
    messagingSenderId: "896412414146",
    appId: "1:896412414146:web:ab8ba208eaa8ee65b04b5b",
    measurementId: "G-HT6PYTM0MS",
}
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service

export default getMessaging(app);
