import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/running-log",
    redirect: "/running-log/new-entry",
  },
  {
    path: "/running-log/new-entry",
    component: () => import("../views/RunningChartNew.vue"),
  },
  {
    path: "/running-log/edit/",
    redirect: "/running-log/new-entry",
  },
  {
    path: "/running-log/edit/:id",
    component: () => import("../views/RunningChartEdit.vue"),
  },
  {
    path: "/running-log/history",
    component: () => import("../views/RunningChartHistory.vue"),
  },
  {
    path: "/employees",
    redirect: "/employees/all",
  },
  {
    path: "/employees/new",
    component: () => import("../views/NewEmployee.vue"),
  },
  {
    path: "/employees/edit/:id",
    component: () => import("../views/EditEmployee.vue"),
  },
  {
    path: "/employees/all",
    component: () => import("../views/AllEmployees.vue"),
  },
  {
    path: "/employees/all/admin",
    component: () => import("../views/AllEmployeesAdmin.vue"),
  },
  {
    path: "/employees/running-log",
    component: () => import("../views/AllRunningChartHistory"),
  },
  {
    path: "/vehicles",
    component: () => import("../views/Vehicle")
  },
  {
    path: "/projects",
    component: () => import("../views/Project")
  },
  {
    path: "/terms",
    component: () => import("../views/Terms")
  },
  {
    path: "/privacy",
    component: () => import("../views/Privacy")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
