<template>
  <!-- App.vue -->

  <v-app>
    <v-app-bar color="#06495c" dark app>
      <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>

      <v-toolbar-title class="app-title">IMSO</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu
        right
        bottom
        :offset-y="true"
        rounded="1"
        v-if="notifications.length > 0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              bottom
              color="red"
              :content="notifications.length"
              offset-x="10"
              offset-y="10"
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list width="200">
          <v-list-item
            three-line
            v-for="notification in notifications"
            :key="notification.id"
            @click="notification_redirect(notification.link, notification.id)"
          >
            <v-list-item-content class="pb-0">
              <v-list-item-title>{{ notification.title }}</v-list-item-title>
              <v-list-item-subtitle>{{
                notification.message
              }}</v-list-item-subtitle>
              <v-divider class="mt-3"></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu right bottom :offset-y="true" rounded="1" v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>
        <v-list width="200">
          <v-list-item one-line>
            <v-list-item-content>
              <v-list-item-title>No notifications</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu right bottom :offset-y="true" rounded="1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="profile = true">
            <v-list-item-title
              ><v-icon> mdi-cog </v-icon> Settings</v-list-item-title
            >
          </v-list-item>
          <v-divider class="mx-2"></v-divider>
          <v-list-item @click="logout">
            <v-list-item-title
              ><v-icon> mdi-logout </v-icon> Logout</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main style="position: relative">
      <v-container fluid>
        <v-row class="d-flex flex-row mb-6">
          <div class="nav" v-if="user">
            <v-navigation-drawer
              v-model="drawer"
              :mini-variant.sync="mini"
              fixed
              permanent
              class="grey lighten-5"
              :mini-variant-width="mini_width"
            >
              <v-list class="mt-15">
                <v-list-item class="px-2">
                  <v-list-item-avatar :color="random_color">
                    <v-img v-if="preview" :src="preview"></v-img>
                    <span v-else class="white--text text-h5"
                      >{{ user.first_name.charAt(0)
                      }}{{ user.last_name.charAt(0) }}</span
                    >
                  </v-list-item-avatar>
                </v-list-item>

                <v-list-item link>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">
                      {{ user.first_name }} {{ user.last_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      user.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <v-list nav>
                <div v-for="item in items" :key="item.title">
                  <v-list-group
                    active-class="primary--text blue-grey lighten-5"
                    :prepend-icon="item.action"
                    no-action
                    v-if="
                      item.items &&
                      item.privileges.includes(parseInt(user.role))
                    "
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <div v-for="child in item.items" :key="child.title">
                      <v-list-item
                        v-if="child.privileges.includes(parseInt(user.role))"
                        link
                        :to="child.path"
                        @click.stop="mini = !mini"
                        active-class="primary--text"
                        class="ps-10"
                      >
                        <v-list-item-content class="ps-8">
                          <v-list-item-title
                            v-text="child.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list-group>

                  <v-list-item
                    v-else-if="item.privileges.includes(parseInt(user.role))"
                    link
                    :to="item.path"
                    @click.stop="mini = !mini"
                    active-class="primary--text"
                    class="my-2"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.action"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-navigation-drawer>
          </div>
          <div>
            <router-view />
          </div>
        </v-row>
        <v-row justify="center">
          <v-dialog
            v-model="profile"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dark color="#06495c">
                <v-btn icon dark @click="close_profile">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Settings</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="save_profile"> Save </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <h3 class="text-center mt-10" style="font-weight: 400">
                Update Profile Picture
              </h3>
              <div class="avatar-upload my-10">
                <div class="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    @change="previewImage"
                  />
                  <label for="imageUpload" class="pt-1 ps-1"
                    ><v-icon>mdi-image</v-icon></label
                  >
                </div>
                <v-list class="mt-5" v-if="user">
                  <v-list-item class="avatar-preview">
                    <v-list-item-avatar
                      :color="random_color"
                      id="imagePreview"
                      size="200"
                    >
                      <v-img v-if="preview" :src="preview"></v-img>
                      <span v-else class="white--text text-h5"
                        >{{ user.first_name.charAt(0)
                        }}{{ user.last_name.charAt(0) }}</span
                      >
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
              </div>
              <v-divider></v-divider>
              <v-container fluid>
                <h3 class="text-center my-10" style="font-weight: 400">
                  Reset Password
                </h3>
                <v-row justify="center">
                  <v-col cols="12" sm="6" md="4" class="my-0 py-0">
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="pwdRules"
                      :type="show1 ? 'text' : 'password'"
                      label="New Password"
                      filled
                      hint="At least 8 characters"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" class="my-0 py-0">
                    <v-text-field
                      v-model="confirm_password"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="pwdRules.concat(passwordConfirmationRule)"
                      :type="show2 ? 'text' : 'password'"
                      label="Confirm Password"
                      filled
                      hint="At least 8 characters"
                      counter
                      @click:append="show2 = !show2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
          <v-dialog v-model="warning" persistent max-width="290">
            <v-card>
              <v-card-title
                class="text-h6 text-center"
                style="word-break: break-word"
              >
                {{ warning_title }}
              </v-card-title>
              <v-card-text class="text-center">
                <div class="d-flex justify-center">
                  <v-icon
                    :color="warning_color_dark"
                    x-large
                    :class="warning_color_light"
                    class="rounded-circle pt-3 pb-3 pl-3 pr-3 mb-3"
                    >{{ warning_icon }}</v-icon
                  >
                </div>
                {{ warning_message }}
              </v-card-text>
              <v-card-actions class="d-flex justify-center pb-3">
                <v-btn
                  :color="warning_color_dark"
                  dark
                  @click="warning = false"
                >
                  OK
                </v-btn>
                <v-btn
                  v-if="warning_action"
                  :color="warning_color_dark"
                  dark
                  @click="warningAction"
                >
                  {{ warning_action_name }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-main>
    <v-footer padless class="main-footer">
      <v-col class="text-center" cols="12">
       <span class="px-5 grey--text lighten-1"> Version {{app_v}}</span> 
        {{ new Date().getFullYear() }} — <strong>OPEnE</strong>
        <a class="web-link" href="http://openesrilanka.org/" target="_blank"
          >openesrilanka.org</a
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<style>
.nav {
  position: absolute;
  height: fit-content;
  z-index: 1;
}

.main-footer {
  margin-left: 50px;
  width: calc(100vw - 50px);
}
@media only screen and (max-width: 600px) {
  .main-footer {
    margin-left: 0px;
    width: 100vw;
  }
}
a.web-link {
  color: gray !important;
  padding: 0 20px;
  text-decoration: none;
}

.app-title {
  font-weight: 900;
  /* letter-spacing: 1px; */
}

.list-parent {
  padding-left: 50px;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 10px auto;
  left: -15px;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
}
</style>
<script>
const axios = require("axios").default;
import compress from "compress-base64";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import alert from "./assets/alert.wav";
export default {
  name: "App",
  data: () => ({
    new_v: null,
    app_v : localStorage.imso_v?localStorage.imso_v:null,
    password: null,
    confirm_password: null,
    show1: false,
    show2: false,
    preview: null,
    mini: true,
    drawer: true,
    user: null,
    profile: false,
    warning: false,
    warning_action: null,
    warning_action_name: null,
    warning_icon: null,
    warning_title: null,
    warning_message: null,
    warning_color_dark: null,
    warning_color_light: null,
    notifications: [],
    sound: false,
    snackbar: false,
    text: ``,
    timeout: 3000,
    widgets: false,
    pwdRules: [(v) => v.length >= 8 || "Min 8 characters"],
    random_color: "#" + ((Math.random() * 0xffffff) << 0).toString(16),
    items: [
      {
        action: "mdi-view-dashboard",
        name: "dashboard",
        title: "Dashboard",
        path: "/",
        privileges: [1, 2, 3, 4, 5],
      },
      {
        action: "mdi-speedometer",
        name: "log",
        privileges: [1, 2, 3, 4, 5],
        items: [
          {
            title: "New Entry",
            path: "/running-log/new-entry",
            privileges: [1, 2, 3, 4, 5],
          },
          {
            title: "History",
            path: "/running-log/history",
            privileges: [1, 2, 3, 4, 5],
          },
        ],
        title: "Running Log",
      },
      {
        action: "mdi-account-group",
        name: "employee",
        privileges: [1, 2, 3, 4, 5],
        items: [
          { title: "New Employee", path: "/employees/new", privileges: [1, 5] },
          {
            title: "All Employees",
            path: "/employees/all",
            privileges: [1, 2, 3, 4, 5],
          },
          {
            title: "Running Log",
            path: "/employees/running-log",
            privileges: [1, 2, 3, 4, 5],
          },
        ],
        title: "Employees",
      },
      {
        action: "mdi-folder-table",
        name: "misc",
        privileges: [1, 4],
        items: [
          { title: "Vehicles", path: "/vehicles", privileges: [1, 4] },
          { title: "Projects", path: "/projects", privileges: [1] },
        ],
        title: "Miscellaneous",
      },
    ],
  }),
  computed: {
    mini_width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        default:
          return 56;
      }
    },
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirm_password || "Password must match";
    },
  },
  methods: {
    warningAction() {
      if (this.warning_action == "__update__") {
        localStorage.imso_v = this.new_v;
        window.location.reload(true)
      } else {
        if (this.warning_action != this.$route.path)
          this.$router.push(this.warning_action);
      }
      this.warning = false;
    },
    notification_redirect(link, id) {
      var removeIndex = this.notifications
        .map(function (item) {
          return item.id;
        })
        .indexOf(id);
      if (link == "__warning__") {
        this.warning = true;
        this.warning_icon = "mdi-oil-level";
        this.warning_title = this.notifications[removeIndex].title;
        this.warning_message = this.notifications[removeIndex].message;
        this.warning_color_dark = "orange darken-2";
        this.warning_color_light = "orange lighten-5";
        this.notifications.splice(removeIndex, 1);
        localStorage.imso_notifications = JSON.stringify(this.notifications);
      } else if (link == "__danger__") {
        this.warning = true;
        this.warning_icon = "mdi-oil-level";
        this.warning_title = this.notifications[removeIndex].title;
        this.warning_message = this.notifications[removeIndex].message;
        this.warning_color_dark = "red darken-2";
        this.warning_color_light = "red lighten-5";
        this.notifications.splice(removeIndex, 1);
        localStorage.imso_notifications = JSON.stringify(this.notifications);
      } else {
        this.notifications.splice(removeIndex, 1);
        localStorage.imso_notifications = JSON.stringify(this.notifications);
        if (link != this.$route.path) {
          this.$router.push(link);
        }
      }
    },
    previewImage() {
      let reader = new FileReader();
      reader.readAsDataURL(document.querySelector("#imageUpload").files[0]);
      reader.onload = () => {
        let img = reader.result;
        let type = img.substring("data:image/".length, img.indexOf(";base64"));
        compress(reader.result, {
          width: 400,
          type: "image/" + type, // default
          max: 200, // max size
          min: 20, // min size
          quality: 0.8,
        }).then((result) => {
          this.preview = result;
        });
      };
    },
    logout() {
      localStorage.removeItem("imso_user");
      this.user = null;
      this.$router.go();
    },
    close_profile() {
      this.preview = this.user.profile;
      this.password = null;
      this.confirm_password = null;
      this.profile = false;
    },
    save_profile() {
      if (this.password || this.confirm_password) {
        if (this.password === this.confirm_password) {
          axios
            .put(this.$backend_url + "reset-employee-password", {
              remember_token: JSON.parse(localStorage.imso_user)[
                "remember_token"
              ],
              email: JSON.parse(localStorage.imso_user)["email"],
              new_password: this.password,
            })
            .then((res) => {
              if (res.data.success === true) {
                this.snackbar = true;
                this.text = `Password reset successfuly`;
                this.profile = false;
              }
            })
            .catch((res) => {
              console.log("Errors:", res);
              this.snackbar = true;
              this.text = `Error occured while sending data.`;
            });
        }
      }
      if (this.preview != this.user.profile) {
        axios
          .put(this.$backend_url + "update-employee-profile", {
            remember_token: JSON.parse(localStorage.imso_user)[
              "remember_token"
            ],
            email: JSON.parse(localStorage.imso_user)["email"],
            new_profile: this.preview,
          })
          .then((res) => {
            if (res.data.success === true) {
              this.snackbar = true;
              this.text = `Profile change successfuly`;
              this.profile = false;
              localStorage.removeItem("imso_user");
              this.user.profile = this.preview;
              localStorage.imso_user = JSON.stringify(this.user);
            }
          })
          .catch((res) => {
            console.log("Errors:", res);
            this.snackbar = true;
            this.text = `Error occured while sending data.`;
          });
      }
    },
  },
  mounted() {
    if (localStorage.imso_user) {
      const messaging = getMessaging();

      getToken(messaging, {
        vapidKey:
          "BDrG-YkkgDBGkzg6XULvcbLOghrvWEF3xs4nE6VdZAxqsiiC_kaP__6qCeIC_iqIY-RG3JFNYT1ZQhS-Y2_j4RI",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log(currentToken);
            localStorage.imso_client_token = currentToken;
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });

      localStorage.imso_client_device = window.navigator.userAgent;

      if (localStorage.imso_client_token && localStorage.imso_client_device) {
        axios
          .post(this.$backend_url + "register-client", {
            remember_token: JSON.parse(localStorage.imso_user)[
              "remember_token"
            ],
            email: JSON.parse(localStorage.imso_user)["email"],
            client_token: localStorage.imso_client_token,
            device: localStorage.imso_client_device,
          })
          .catch((res) => {
            console.log("Errors:", res);
            this.snackbar = true;
            this.text = `Error occured while sending data.`;
          });
      }

      if (localStorage.imso_notifications) {
        this.notifications = JSON.parse(localStorage.imso_notifications);
      }

      onMessage(messaging, (payload) => {
        this.notifications.push(payload.data);
        localStorage.imso_notifications = JSON.stringify(this.notifications);
        const audio = new Audio(alert);
        audio.play();
      });

      this.user = JSON.parse(localStorage.imso_user);
      this.preview = this.user.profile;
      axios
        .get(this.$backend_url + "oil-change-alert", {
          params: {
            remember_token: JSON.parse(localStorage.imso_user)[
              "remember_token"
            ],
            email: JSON.parse(localStorage.imso_user)["email"],
          },
        })
        .then((response) => {
          let danger = response.data
            .map(function (item) {
              return item.link;
            })
            .indexOf("__danger__");

          if (danger > -1) {
            this.warning = true;
            this.warning_icon = "mdi-oil-level";
            this.warning_title = response.data[danger].title;
            this.warning_message = response.data[danger].message;
            this.warning_color_dark = "red darken-2";
            this.warning_color_light = "red lighten-5";
            const audio = new Audio(alert);
            audio.play();
          } else {
            let warning = response.data
              .map(function (item) {
                return item.link;
              })
              .indexOf("__warning__");
            if (warning > -1) {
              this.warning = true;
              this.warning_icon = "mdi-oil-level";
              this.warning_title = response.data[warning].title;
              this.warning_message = response.data[warning].message;
              this.warning_color_dark = "orange darken-2";
              this.warning_color_light = "orange lighten-5";
            }
          }
        });

      if (localStorage.imso_running_log_temp) {
        this.warning = true;
        this.warning_icon = "mdi-gauge";
        this.warning_action = "/running-log/new-entry";
        this.warning_action_name = "Fill Now";
        this.warning_title = "Fill out your log completely!";
        this.warning_message =
          "You had already started to fill out the running log. Please make sure to complete it before the day ends.";
        this.warning_color_dark = "blue darken-2";
        this.warning_color_light = "blue lighten-5";
      }
    } else {
      this.$router.push("/login");
    }   

    // check the version
    axios
      .get(this.$backend_url + "app-version", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
        },
      })
      .then((response) => {
        if (localStorage.imso_v) {
          if (localStorage.imso_v != response.data) {
            this.warning = true;
            this.warning_icon = "mdi-update";
            this.warning_action = "__update__";
            this.warning_action_name = "Update now";
            this.warning_title = "Get the latest IMSO updates!";
            this.warning_message =
              "You should uninstall and then reinstall the app to get the latest IMSO updates available for you.";
            this.warning_color_dark = "teal darken-2";
            this.warning_color_light = "teal lighten-5";
            this.new_v = response.data;
          }
        } else {
          this.warning = true;
          this.warning_icon = "mdi-update";
          this.warning_action = "__update__";
          this.warning_action_name = "Update now";
          this.warning_title = "Get the latest IMSO updates!";
          this.warning_message =
            "You should uninstall and then reinstall the app to get the latest IMSO updates available for you.";
          this.warning_color_dark = "teal darken-2";
          this.warning_color_light = "teal lighten-5";
          this.new_v = response.data;
        }
      });
  },
};
</script>
