import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import router from "./router";
import GAuth from "vue-google-oauth2";
import excel from "vue-excel-export";
import firebaseMessaging from "./firebase";

Vue.prototype.$messaging = firebaseMessaging;

Vue.prototype.$backend_url = "https://api.imso.openesrilanka.org/api/"; // Live
// Vue.prototype.$backend_url = "https://testing.api.imso.openesrilanka.org/api/" // development or testing
// Vue.prototype.$backend_url = "http://127.0.0.1:8000/api/"; // Local

Vue.use(excel);

const gauthOption = {
  clientId:
    "989507358438-qjti9jvuoii1v8ekqe4ouh681epgiq56.apps.googleusercontent.com",
  scope: "profile email",
};

Vue.use(GAuth, gauthOption);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
